import { Title } from '@solidjs/meta';
import { Button, Heading, Link, LinkButton, Section, Tag } from '@troon/ui';
import { useUser } from '../../providers/user';
import { FrequentlyAskedQuestions } from '../../components/faqs';

export default function Activity() {
	const user = useUser();

	return (
		<Section>
			<Title>My Troon Card | My account | Troon</Title>
			<Heading as="h1">Troon Card</Heading>

			<div class="mb-8 flex flex-col gap-6 rounded border border-neutral p-4 md:mb-16 md:gap-8 md:p-6">
				<Tag>Current</Tag>

				<div class="flex flex-row items-center justify-between">
					<Heading as="h2" size="h5">
						{user()?.me.card?.name}
					</Heading>
					<Button as={Link} href={`/card/${user()?.me.card?.id}`} class="size-fit shrink grow-0">
						Book with Troon Card
					</Button>
				</div>

				<p class="flex flex-row flex-wrap items-center justify-between gap-2 rounded bg-neutral-100 p-3">
					<span>
						Your <b>2024 {user()?.me.card?.name}</b> expires on 12/31/2024.
					</span>
					<LinkButton href="/card/terms">Terms & Conditions</LinkButton>
				</p>
			</div>

			<Section>
				<Heading as="h2" size="h3">
					Frequently asked questions
				</Heading>
				<div class="rounded border border-neutral">
					<FrequentlyAskedQuestions id="2024-troon-card-faqs" />
				</div>
			</Section>
		</Section>
	);
}
